import React from 'react'
import Footer from 'components/theme/Footer'
import './fonts.css'
import { Global } from './styles'

export const Layout = ({ lang, children }) => (
    <>
      <Global />
        {children}
      <Footer />
    </>
)
