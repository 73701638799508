module.exports = {
  defaultTitle: 'Dev Workshops',
  logo: 'https://dev-workshops.smakosh.com/favicon/favicon-512.png',
  author: 'Dev Workshops',
  url: 'https://dev-workshops.smakosh.com',
  legalName: 'Dev Workshops',
  defaultDescription: 'State of the art JavaScript workshops',
  socialLinks: {
    twitter: 'http://www.twitter.com/hire-dev',
    github: 'https://github.com/hire-dev',
    linkedin: 'https://www.linkedin.com/in/hire-dev',
    instagram: 'https://instagram.com/hire-dev',
    youtube: 'https://www.youtube.com/user/hire-dev',
    google: 'https://plus.google.com/u/0/+hire-dev',
  },
  googleAnalyticsID: 'UA-88875900-9',
  themeColor: '#4285f4',
  backgroundColor: '#4285f4',
  social: {
    facebook: 'xxxxxxx',
    twitter: '@smakosh',
  },
  address: {
    city: 'Errachidia',
    region: 'Deraa-Tafilalet',
    country: 'Morocco',
    zipCode: '52000',
  },
  contact: {
    email: 'ismai23l@hotmail.com',
    phone: '+212663532761',
  },
  foundingDate: '2019',
  recaptcha_key: '6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN',
}
