import React, { useState } from 'react'
import arrowIcon from './arrow.svg'
import { Wrapper, Title, Arrow, Details } from './styles'

export const Accordion = ({ title, description }) => {
  const [isVisible, toggle] = useState(true)

  return (
    <Wrapper>
      <Title onClick={() => toggle(!isVisible)}>
        <h4>{title}</h4>
        <Arrow src={arrowIcon} alt={'arrow-icon'} active={isVisible} />
      </Title>
      <Details active={isVisible}>
        <p>{description}</p>
      </Details>
    </Wrapper>
  )
}
