import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 8rem 0;

  @media (max-width: 960px) {
    padding: 2rem 0;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0.5rem;

    strong {
      font-size: 14pt;
      border-bottom: 3px solid;
      border-image-source: linear-gradient(62deg, #4285f4 0%, #b721ff 100%);
      border-image-slice: 1;
      margin: 0 0.2rem;
    }
  }
`

export const FlexHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;

  a {
    color: #000;
    font-size: 14pt;
  }

  @media (max-width: 680px) {
    flex-direction: row;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export const Item = styled.div`
  width: 100%;
  max-width: 48%;

  @media (max-width: 960px) {
    max-width: 100%;

    &:last-child {
      display: none;
    }
  }
`
